import React, { useState, useEffect } from "react"
import { fetchPageContents } from "../components/utilities"
import Layout from "../components/layout"
import Seo from "../components/seo"

const UserGuide = () => {
  const [pageContents, setPageContents] = useState("");

  useEffect(() => {
    fetchPageContents("user-guide")
      .then(text => setPageContents(text));
  },[]);

  return (
    <Layout>
      <Seo title="User Guide" />
      <div>
        <div align="center">&nbsp;</div>
        <div
          dangerouslySetInnerHTML={{__html: pageContents}}>
        </div>
      </div>
    </Layout>
  )
}

export default UserGuide
